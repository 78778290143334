<template>
  <li
    v-if="canViewVerticalNavMenuLink(item)"
    class="nav-item"
    :class="{
      'active': isActive,
      'disabled': item.disabled
    }"
  >
    <b-link
      :to="item.route === 'task-filter' ? { name: item.route, params: { filter: 'unfinished' } } : { name: item.route }"
      class="d-flex align-items-center"
    >
      <feather-icon :icon="item.icon || 'CircleIcon'" />
      <span class="menu-title text-truncate">{{ t(item.title) }}</span>
      <b-badge
        v-if="item.tagGetters && $store.getters[item.tagGetters] !== 0"
        v-b-tooltip.hover
        title="Task belum diselesaikan"
        pill
        :variant="item.tagVariant || (isActive ? 'primary' : 'light-primary')"
        class="ml-auto"
      >
        {{ $store.getters[item.tagGetters] }}
      </b-badge>
      <div
        v-if="item.route === 'quotations'"
        class="d-flex ml-auto"
      >
        <b-badge
          v-if="quoteBagde.quote_submitted && $root.role === 2"
          v-b-tooltip.hover
          title="Quote Disubmit"
          pill
          :variant="isActive ? 'danger' : 'light-danger'"
          class="ml-25"
        >
          {{ quoteBagde.quote_submitted > 99 ? '99+' : quoteBagde.quote_submitted }}
        </b-badge>
        <b-badge
          v-if="quoteBagde.quote_action"
          v-b-tooltip.hover
          title="Quote disubmit dan membutuhkan aksi"
          pill
          :variant="isActive ? 'warning' : 'light-warning'"
          class="ml-25"
        >
          {{ quoteBagde.quote_action > 99 ? '99+' : quoteBagde.quote_action }}
        </b-badge>
        <b-badge
          v-if="quoteBagde.all_quote_action && $root.role === 2"
          v-b-tooltip.hover
          title="Semua quote yang membutuhkan aksi"
          pill
          :variant="isActive ? 'danger' : 'light-danger'"
          class="ml-25"
        >
          {{ quoteBagde.all_quote_action > 99 ? '99+' : quoteBagde.all_quote_action }}
        </b-badge>
      </div>
    </b-link>
  </li>
</template>

<script>
import { useUtils as useAclUtils } from '@core/libs/acl'
import { BLink, BBadge, VBTooltip } from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { mapGetters } from 'vuex'
import useVerticalNavMenuLink from './useVerticalNavMenuLink'
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink'

export default {
  components: {
    BLink,
    BBadge,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      quoteBagde: 'getQuotesBadge',
    }),
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(props.item)
    const { t } = useI18nUtils()
    const { canViewVerticalNavMenuLink } = useAclUtils()

    return {
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t,
    }
  },

}
</script>
