<template>
  <b-nav-item-dropdown
    ref="dropdown"
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-add"
    left
  >
    <template #button-content>
      <feather-icon
        badge-classes="bg-primary"
        class="text-body"
        icon="PlusSquareIcon"
        size="21"
      />
    </template>

    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area px-1 pt-50"
      tagname="li"
      style="min-height: 350px !important"
    >
      <div
        v-for="menu in menus"
        :key="menu.type"
      >
        <small class="text-muted d-block">
          {{ menu.type }}
        </small>
        <b-row class="my-1">
          <b-col
            v-for="list in menu.list"
            :key="list.name"
            cols="4"
            @click="$refs.dropdown.hide()"
          >
            <router-link
              class="d-flex flex-column align-items-center"
              :to="list.route"
            >
              <b-avatar
                variant="light-primary"
                class="mb-50"
                size="42"
              >
                <template #badge>
                  <feather-icon
                    size="12"
                    icon="PlusIcon"
                  />
                </template>
                <feather-icon
                  size="24"
                  :icon="list.icon"
                />
              </b-avatar>
              <small class="text-primary text-center">{{ list.name }}</small>
            </router-link>
          </b-col>
        </b-row>
      </div>
    </vue-perfect-scrollbar>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BCol, BRow, BAvatar,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BNavItemDropdown,
    BCol,
    BRow,
    BAvatar,
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      menus: [
        {
          type: 'Master',
          list: [
            {
              name: 'Customer',
              route: { name: 'customer-add' },
              icon: 'HeartIcon',
            },
            {
              name: 'Project',
              route: { name: 'addproject' },
              icon: 'HomeIcon',
            },
            {
              name: 'Scheme',
              route: { name: 'addscheme' },
              icon: 'SlidersIcon',
            },
          ],
        },
        {
          type: 'Transactions',
          list: [
            {
              name: 'Quotes',
              route: { name: 'addquote', params: { resetFormState: true } },
              icon: 'FileTextIcon',
            },
            {
              name: 'Sales Order',
              route: { name: 'salesorder-add' },
              icon: 'TagIcon',
            },
            {
              name: 'Dry Report',
              route: { name: 'adddryreport' },
              icon: 'ClipboardIcon',
            },
          ],
        },
        {
          type: 'Personal',
          list: [
            {
              name: 'Task',
              route: { name: 'task-filter', params: { filter: 'unfinished', openAddForm: true } },
              icon: 'CheckSquareIcon',
            },
          ],
        },
      ],
    }
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 200,
      wheelPropagation: false,
    }

    return {
      perfectScrollbarSettings,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
// Overrides user variable
@import '~@core/scss/base/components/include';

.header-navbar .navbar-container .dropdown-menu-add {
  width: 320px;
}
</style>
