<template>
  <li class="nav-item nav-search">

    <!-- Icon -->
    <a
      href="javascript:void(0)"
      class="nav-link nav-link-search"
      @click="showSearchBar = true"
    >
      <feather-icon
        icon="SearchIcon"
        size="21"
      />
    </a>

    <!-- Input -->
    <div
      class="search-input"
      :class="{'open': showSearchBar}"
    >
      <div class="search-input-icon">
        <feather-icon icon="SearchIcon" />
      </div>
      <b-form-input
        v-if="showSearchBar"
        v-model="searchQuery"
        placeholder="Cari di Quotation System"
        autofocus
        autocomplete="off"
        @keyup.up="increaseIndex(false)"
        @keyup.down="increaseIndex"
        @keyup.esc="showSearchBar = false; resetsearchQuery()"
        @keyup.enter="suggestionSelected"
      />
      <div
        class="search-input-close"
        @click="showSearchBar = false; resetsearchQuery()"
      >
        <feather-icon icon="XIcon" />
      </div>

      <!-- Suggestions List -->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="search-list search-list-main scroll-area overflow-hidden"
        :class="{'show': searchQuery}"
        tagname="ul"
      >
        <div class="p-1 d-flex flex-wrap align-items-center">
          <h6
            v-if="!filterList.length"
            class="text-muted m-0 my-1 font-weight"
          >
            {{ loadingSearch ? 'Tunggu sebentar yaa...' : 'Hasil pencarian masih kosong' }}
          </h6>
          <b-badge
            v-for="(filter, id) in filterList"
            v-else
            :key="id"
            class="px-1 py-50 mr-50 mb-50 mt-50 cursor-pointer font-weight-normal"
            pill
            :variant="filter.active ? 'primary' : 'secondary'"
            @click="filterList[id].active = !filter.active"
          >
            {{ title(filter.name) }}
          </b-badge>
          <b-spinner
            v-if="loadingSearch"
            style="width: 1.4rem; height: 1.4rem;"
            class="ml-auto mb-50 mt-50"
            variant="primary"
          />
        </div>
        <li
          v-for="(suggestion_list, grp_name, grp_index) in filteredData"
          :key="grp_index"
          class="suggestions-groups-list"
          :class="{ 'd-none' : !resolveActiveResult(grp_name) }"
        >
          <!-- Group Header -->
          <p class="suggestion-group-title">
            <span>
              {{ title(grp_name) }}
            </span>
          </p>

          <!-- Suggestion List of each group -->
          <ul>
            <li
              v-for="(suggestion, index) in suggestion_list"
              :key="index"
              class="suggestion-group-suggestion cursor-pointer"
              :class="{'suggestion-current-selected': currentSelected === `${grp_index}.${index}`}"
              @mouseenter="currentSelected = `${grp_index}.${index}`"
              @mousedown.prevent="suggestionSelected(grp_name, suggestion)"
            >
              <div
                v-if="grp_name === 'customers'"
                class="d-flex align-items-center"
              >
                <b-avatar
                  size="38px"
                  variant="light-primary"
                  :text="avatarText(suggestion.name)"
                />
                <div class="ml-50">
                  <h6 class="m-0 text-primary">
                    {{ suggestion.name }}
                  </h6>
                  <small class="text-muted d-block">
                    {{ suggestion.info.customerNo }}
                  </small>
                  <small>
                    <b-badge
                      v-if="suggestion.info.category"
                      variant="light-primary"
                    >
                      {{ suggestion.info.category }}
                    </b-badge>
                  </small>
                </div>
              </div>
              <div
                v-else-if="grp_name === 'project'"
                class="d-flex align-items-center"
              >
                <b-avatar
                  size="38px"
                  variant="light-primary"
                >
                  <feather-icon
                    icon="HomeIcon"
                    size="20"
                  />
                </b-avatar>
                <div class="ml-50">
                  <h6 class="m-0 text-primary">
                    {{ suggestion.name }}
                  </h6>
                  <small
                    v-if="suggestion.info.tgl_reminder"
                    class="text-muted"
                  >
                    Tanggal Reminder : {{ formatDate(suggestion.info.tgl_reminder) }}
                  </small>
                </div>
              </div>
              <div
                v-else-if="grp_name === 'quotes'"
                class="d-flex align-items-center"
              >
                <b-avatar
                  size="38px"
                  variant="light-primary"
                >
                  <feather-icon
                    icon="FileTextIcon"
                    size="20"
                  />
                </b-avatar>
                <div class="ml-50">
                  <h6 class="m-0 text-primary">
                    {{ suggestion.name }}
                  </h6>
                  <small class="text-muted">
                    Dibuat : {{ formatDate(suggestion.info.created_at) }} - {{ suggestion.info.project }}
                  </small>
                </div>
                <b-badge
                  class="ml-auto"
                  variant="secondary"
                >
                  {{ suggestion.info.status }}
                </b-badge>
              </div>
              <div
                v-else-if="grp_name === 'items'"
                class="d-flex align-items-center"
              >
                <b-avatar
                  size="38px"
                  :style="suggestion.info.type === 'UMUM' ? `background: #${suggestion.info.color} !important;` : ''"
                  :variant="suggestion.info.type === 'UMUM' ? 'light-primary' : suggestion.info.type === 'AGENT' ? 'secondary' : 'light-secondary'"
                  :icon="suggestion.info.type === 'UMUM' ? 'circle' : null"
                  :text="suggestion.info.type === 'UMUM' ? '' : suggestion.info.type === 'AGENT' ? 'AG' : 'TH'"
                />
                <div class="ml-50">
                  <h6 class="m-0 text-primary">
                    {{ suggestion.name }}
                  </h6>
                  <small class="text-muted">
                    <small>{{ suggestion.info.item_code }} - {{ suggestion.info.itemCategory }} {{ suggestion.info.liter ? `- ${suggestion.info.liter} Liter` : null }}</small>
                  </small>
                </div>
              </div>
              <div
                v-else-if="grp_name === 'scheme'"
                class="d-flex align-items-center"
              >
                <b-avatar
                  size="38px"
                  variant="light-primary"
                  :style="`background: #${suggestion.info.color} !important;`"
                  icon="circle"
                />
                <div class="ml-50">
                  <h6 class="m-0 text-primary">
                    {{ suggestion.name }}
                  </h6>
                  <small class="text-muted">
                    {{ suggestion.info.notes }}
                  </small>
                </div>
              </div>
              <div
                v-else-if="grp_name === 'users'"
                class="d-flex align-items-center"
              >
                <b-avatar
                  size="38px"
                  variant="light-primary"
                  :src="suggestion.info.photo && suggestion.info.photo !== 'default.png' ?
                    `${$restHTTP}/assets/img/profile-picture/${suggestion.id}/${suggestion.info.photo}` :
                    `${$restHTTP}/assets/img/profile-picture/default.png`
                  "
                />
                <div class="ml-50">
                  <h6 class="m-0 text-primary">
                    {{ suggestion.name }}
                  </h6>
                  <small class="text-muted">
                    {{ suggestion.info.role }}
                  </small>
                </div>
              </div>
              <div
                v-else-if="grp_name === 'tasks'"
                class="d-flex align-items-center"
              >
                <b-avatar
                  size="38px"
                  :variant="suggestion.info.status === 1 ? 'light-primary' : 'light-secondary'"
                >
                  <feather-icon
                    icon="CheckSquareIcon"
                    size="20"
                  />
                </b-avatar>
                <div class="ml-50">
                  <h6 class="m-0 text-primary">
                    {{ suggestion.name }}
                  </h6>
                  <small v-if="suggestion.info.tags">
                    <b-badge
                      v-for="tag in suggestion.info.tags"
                      :key="tag.id"
                      :variant="`light-${tagsVariant[tag.id].variant}`"
                      class="mr-50 mt-25"
                    >
                      {{ tag.name }}
                    </b-badge>
                  </small>
                </div>
              </div>
            </li>

            <li
              v-if="!suggestion_list.length && searchQuery"
              class="suggestion-group-suggestion no-results"
            >
              <p>Tidak Ditemukan</p>
            </li>
          </ul>
        </li>
      </vue-perfect-scrollbar>
    </div>
  </li>
</template>

<script>
import {
  BFormInput, BAvatar, BBadge, BSpinner,
} from 'bootstrap-vue'
import { ref, watch } from '@vue/composition-api'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { title, avatarText, formatDate } from '@core/utils/filter'
import router from '@/router'
import store from '@/store'
import searchAndBookmarkData from '../search-and-bookmark-data'

export default {
  components: {
    BFormInput,
    BAvatar,
    BBadge,
    BSpinner,
    VuePerfectScrollbar,
  },
  data() {
    return {
      tagsVariant: {
        1: {
          variant: 'danger',
        },
        2: {
          variant: 'warning',
        },
        3: {
          variant: 'primary',
        },
        4: {
          variant: 'info',
        },
        5: {
          variant: 'info',
        },
        6: {
          variant: 'info',
        },
        7: {
          variant: 'info',
        },
        8: {
          variant: 'info',
        },
      },
    }
  },
  setup() {
    const searchQuery = ref('')
    const showSearchBar = ref(false)
    const filteredData = ref({})
    const loadingSearch = ref(false)
    const filterList = ref([])
    const perfectScrollbarSettings = {
      maxScrollbarLength: 300,
      wheelPropagation: false,
    }

    const resolveRouteData = (grpName, data) => {
      const routeData = {
        customers: { name: 'customer-detail', params: { id: data.id } },
        project: { name: 'project-detail', params: { id: data.id } },
        quotes: data.info?.last_version ? { name: 'quotation-detail', params: { id: data.id.toString(), version: data.info.last_version.toString() } } : null,
        items: { name: 'item-detail', params: { id: data.id.toString() } },
        scheme: { name: 'scheme-detail', params: { id: data.id } },
        tasks: { path: '/tasks/all', query: { q: data.name } },
      }

      return routeData[grpName] ?? null
    }

    const suggestionSelected = (grpName, suggestion) => {
      // If parameter is not provided => Use current selected
      if (!suggestion) {
        // If currentSelected value is -1 => No value/item is selected (Prevent Errors)
        /* eslint-disable no-use-before-define, no-param-reassign */
        if (currentSelected.value !== -1) {
          /* eslint-disable no-use-before-define, no-param-reassign */
          const [grpIndex, itemIndex] = currentSelected.value.split('.')
          grpName = Object.keys(filteredData.value)[grpIndex]
          suggestion = filteredData.value[grpName][itemIndex]
          /* eslint-enable */
        }
      }
      if (grpName === 'items' && !suggestion.info.isInDB) {
        const data = {
          id: suggestion.id,
          name: suggestion.name,
          no: suggestion.info.item_code,
          itemCategory: {
            id: 51,
            name: suggestion.info.itemCategory,
          },
        }
        router.push({ name: 'items', params: { data } }).catch(() => {
          router.push({ name: 'dashboard' }).then(() => {
            router.push({ name: 'items', params: { data } })
          })
        })
      } else {
        console.log(grpName, suggestion)
        router.push(resolveRouteData(grpName, suggestion)).catch(() => {})
      }
      // eslint-disable-next-line no-use-before-define
      resetsearchQuery()
      showSearchBar.value = false
    }

    const resetsearchQuery = () => {
      searchQuery.value = ''
    }

    let timeout = null
    const fetchResult = () => {
      loadingSearch.value = true
      if (timeout) {
        clearTimeout(timeout)
      }

      timeout = setTimeout(async () => {
        await store.dispatch('globalSearch', searchQuery.value).then(result => {
          const data = {}
          filterList.value = []
          result.forEach(el => {
            // eslint-disable-next-line no-prototype-builtins
            if (data.hasOwnProperty(el.type)) {
              data[el.type].push({
                ...el,
                info: el.info ? JSON.parse(el.info) : null,
              })
            } else {
              data[el.type] = [{
                ...el,
                info: el.info ? JSON.parse(el.info) : null,
              }]
            }

            if (!filterList.value.some(filter => filter.name.toLowerCase() === el.type)) {
              filterList.value.push({
                name: el.type,
                active: true,
              })
            }
          })

          filteredData.value = data
          loadingSearch.value = false
        })
      }, 500)
    }

    const resolveActiveResult = grpName => filterList.value.filter(el => el.name.toLowerCase() === grpName)[0].active

    watch(searchQuery, val => {
      if (searchQuery.value.length > 2) {
        fetchResult()
      }
      store.commit('app/TOGGLE_OVERLAY', Boolean(val))
    })

    const currentSelected = ref(-1)
    watch(filteredData, val => {
      if (!Object.values(val).some(obj => obj.length)) {
        currentSelected.value = -1
      } else {
        // Auto Select first item if it's not item-404
        let grpIndex = null

        // eslint-disable-next-line no-restricted-syntax
        for (const [index, grpSuggestions] of Object.values(val).entries()) {
          if (grpSuggestions.length) {
            grpIndex = index
            break
          }
        }

        if (grpIndex !== null) currentSelected.value = `${grpIndex}.0`
      }
    })

    const increaseIndex = (val = true) => {
      /* eslint-disable no-lonely-if, no-plusplus */

      // If there's no matching items
      if (!Object.values(filteredData.value).some(grpItems => grpItems.length)) return

      const [grpIndex, itemIndex] = currentSelected.value.split('.')

      const grpArr = Object.entries(filteredData.value)
      const activeGrpTotalItems = grpArr[grpIndex][1].length

      if (val) {
        // If active item is not of last item in grp
        if (activeGrpTotalItems - 1 > itemIndex) {
          currentSelected.value = `${grpIndex}.${Number(itemIndex) + 1}`

        // If active item grp is not last in grp list
        } else if (grpIndex < grpArr.length - 1) {
          for (let i = Number(grpIndex) + 1; i < grpArr.length; i++) {
            // If navigating group have items => Then move in that group
            if (grpArr[i][1].length > 0) {
              currentSelected.value = `${Number(i)}.0`
              break
            }
          }
        }
      } else {
        // If active item is not of first item in grp
        if (Number(itemIndex)) {
          currentSelected.value = `${grpIndex}.${Number(itemIndex) - 1}`

        // If active item grp  is not first in grp list
        } else if (Number(grpIndex)) {
          for (let i = Number(grpIndex) - 1; i >= 0; i--) {
            // If navigating group have items => Then move in that group
            if (grpArr[i][1].length > 0) {
              currentSelected.value = `${i}.${grpArr[i][1].length - 1}`
              break
            }
          }
        }
      }
      /* eslint-enable no-lonely-if, no-plusplus */
    }

    return {
      loadingSearch,
      showSearchBar,
      filterList,
      perfectScrollbarSettings,
      searchAndBookmarkData,
      title,
      suggestionSelected,
      currentSelected,
      increaseIndex,
      resolveActiveResult,
      resolveRouteData,

      // AutoSuggest
      searchQuery,
      resetsearchQuery,
      filteredData,
      avatarText,
      formatDate,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

ul
{
  list-style: none;
  padding: 0;
  margin: 0;
}
p {
  margin: 0;
}

/* .app-auto-suggest {
  position: relative;
}

.auto-suggest-suggestions-list {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  border-radius: 6px;
  position: absolute;
  top: calc(100% + 1rem);
  background-color: white;
  width: 100%;
} */

.suggestion-group-title {
  font-weight: 500;
  padding: .75rem 1rem .25rem;
}

.suggestion-group-suggestion {
  padding: .75rem 1rem
}

.suggestion-current-selected {
  background-color: $body-bg;

  .dark-layout & {
    background-color: $theme-dark-body-bg;
  }
}
</style>
