<template>
  <b-nav-item-dropdown
    ref="dropdown"
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="notificationCount"
        badge-classes="bg-primary"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex align-items-center flex-wrap">
        <h4 class="notification-title mb-0 mr-auto">
          Notifikasi
        </h4>
        <b-badge
          v-if="notificationCount > 0"
          pill
          variant="light-primary"
          class="cursor-pointer mr-75"
          @click="readAllNotifications()"
        >
          Tandai Semua Dibaca
        </b-badge>
        <feather-icon
          icon="RefreshCcwIcon"
          class="text-primary cursor-pointer"
          size="18"
          @click="fetchNotifications()"
        />
      </div>
    </li>

    <!-- Notifications -->
    <b-overlay :show="loading">
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="scrollable-container media-list scroll-area"
        tagname="li"
        style="min-height: 350px !important"
      >
        <div
          v-for="(notification, index) in notifications"
          :key="index"
        >
          <b-link
            :to="notification.category.id === 1 ?
              { name: 'quotation-detail', params: { id: notification.reference_id, version: notification.info.last_version } } :
              { path: '/tasks/all', query: { q: notification.info.description } }
            "
            @click="!notification.is_read ? readNotification(notification.id) : null; $refs.dropdown.hide()"
          >
            <b-media
              class="notifmedia pl-75 pr-1 py-1"
              :class="{ 'unread' : !notification.is_read }"
            >
              <template #aside>
                <b-avatar
                  size="36"
                  variant="light-primary"
                >
                  <feather-icon
                    :icon="notification.category.id === 1 ? 'FileTextIcon' : 'CheckSquareIcon'"
                    size="20"
                  />
                </b-avatar>
              </template>
              <p class="media-heading text-primary">
                <span class="font-weight-bolder">
                  {{ notification.message ? notification.message : 'No Message' }}
                </span>
              </p>
              <small
                v-if="notification.category.id === 1"
                class="notification-text d-block mb-0 mt-25"
              >{{ notification.info.name }} - {{ notification.project.name }}</small>
              <small
                v-else-if="notification.category.id === 2"
                class="notification-text d-block mb-0 mt-25"
              >{{ notification.info.description }}</small>
              <div class="d-flex justify-content-between align-items-start mt-25">
                <small class="notification-text mb-0 text-right">
                  {{ timesAgo(notification.created_at) }}
                </small>
              </div>
            </b-media>
          </b-link>
        </div>
      </vue-perfect-scrollbar>
    </b-overlay>

    <li
      v-if="!fullResult"
      class="dropdown-menu-footer"
    >
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        @click="loadMore()"
      >
        Lihat Lebih Banyak
      </b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BOverlay, BButton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    BOverlay,
    BButton,
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      limit: 5,
      fullResult: false,
    }
  },
  computed: {
    ...mapGetters({
      notifications: 'getNotificationList',
      loading: 'getLoadingNotification',
      notificationCount: 'getNotificationCount',
    }),
  },
  mounted() {
    if (this.notifications.length < 1) {
      this.fetchNotifications()
    }
  },
  methods: {
    fetchNotifications() {
      this.$store.dispatch('getNotifications', {
        limit: this.limit,
        offset: 0,
      })
    },
    readNotification(id) {
      this.$store.dispatch('readNotification', id).then(() => {
        this.fetchNotifications()
      })
    },
    timesAgo(time) {
      return moment(time.split('.')[0]).locale('id').fromNow()
    },
    loadMore() {
      const limit = this.limit + 5

      if (this.limit <= this.notifications.length) {
        this.limit = limit
        this.fetchNotifications()
      } else {
        this.fullResult = true
      }
    },
    readAllNotifications() {
      this.$store.commit('setLoadingNotification', true)
      this.$store.dispatch('readAllNotifications').then(() => {
        this.fetchNotifications()
      })
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 200,
      wheelPropagation: false,
    }

    return {
      perfectScrollbarSettings,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
// Overrides user variable
@import '~@core/scss/base/components/include';

.unread {
  background: rgba($primary, .2);
}

.unread:hover {
  background: rgba($primary, .3) !important;
}

.notifmedia .media-aside {
  margin-right: 0.6rem;
}
</style>
