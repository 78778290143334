<template>
  <XyzTransitionGroup
    appear
    class="item-group"
    xyz="fade stagger-1 left-3 duration-5"
  >
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in menu"
      :key="item.header || item.title"
      :item="item"
    />
  </XyzTransitionGroup>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref } from '@vue/composition-api'
import { mapGetters } from 'vuex'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      permission: 'getUserPermission',
    }),
    menu() {
      return this.items.filter(item => this.permission[item.permission])
    },
  },
  setup() {
    provide('openGroups', ref([]))

    return {
      resolveNavItemComponent,
    }
  },
}
</script>
