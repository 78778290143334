export default [
  {
    title: 'Dashboard',
    route: 'home',
    icon: 'GridIcon',
    permission: 'homePage',
  },
  {
    header: 'Customer & Product',
    permission: 'homePage',
  },
  {
    title: 'Customers',
    route: 'customers',
    icon: 'HeartIcon',
    permission: 'customerPage',
  },
  {
    title: 'Projects',
    route: 'projects',
    icon: 'HomeIcon',
    permission: 'projectPage',
  },
  {
    title: 'Items',
    route: 'items',
    icon: 'BoxIcon',
    permission: 'itemPage',
  },
  {
    title: 'Scheme',
    route: 'scheme',
    icon: 'SlidersIcon',
    permission: 'schemePage',
  },
  {
    header: 'Transactions',
    permission: 'homePage',
  },
  {
    title: 'Quotes',
    route: 'quotations',
    icon: 'FileTextIcon',
    permission: 'quotePage',
  },
  {
    title: 'Dry Report',
    route: 'dryreport',
    icon: 'ClipboardIcon',
    permission: 'reportPage',
  },
  {
    title: 'Sales Order',
    route: 'salesorder',
    icon: 'TagIcon',
    permission: 'reportPage',
  },
  {
    title: 'Reports',
    route: 'reports',
    icon: 'BookOpenIcon',
    permission: 'reportPage',
  },
  {
    header: 'Personal',
    permission: 'taskPage',
  },
  {
    title: 'Tasks',
    route: 'task-filter',
    params: 'unfinished',
    icon: 'CheckSquareIcon',
    permission: 'taskPage',
    tagGetters: 'getUnfinishedTask',
  },
  {
    header: 'Administrator',
    permission: 'userPage',
  },
  {
    title: 'Users',
    route: 'users',
    icon: 'UserIcon',
    permission: 'userPage',
  },
]
