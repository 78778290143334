<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>
    <b-navbar-nav class="nav align-items-center">
      <dark-Toggler class="d-block" />
      <quick-add class="d-block" />
    </b-navbar-nav>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <search-bar />
      <notification-dropdown />
      <b-nav-item-dropdown
        v-if="user"
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template
          v-if="user"
          #button-content
        >
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ user.user.name }}
            </p>
            <span class="user-status mb-25">{{ user.user.role.name }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            class="badge-minimal"
            :text="avatarText(user.user.name)"
            :src="user.user.photo ? user.user.photo : null"
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          :to="{ name: 'user-detail', params: { id: user.user.id.toString() } }"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          :to="{ name: 'profilesetting' }"
        >
          <feather-icon
            size="16"
            icon="SettingsIcon"
            class="mr-50"
          />
          <span>Pengaturan</span>
        </b-dropdown-item>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          :to="{ name: 'task-filter', params: { filter: 'unfinished' } }"
        >
          <feather-icon
            size="16"
            icon="CheckSquareIcon"
            class="mr-50"
          />
          <span>Task</span>
          <b-badge
            class="ml-auto"
            variant="light-primary"
          >
            {{ $store.getters.getUnfinishedTask }}
          </b-badge>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center text-danger"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, BBadge,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import { mapGetters } from 'vuex'
import { avatarText } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import NotificationDropdown from '@/components/misc/NotificationDropdown.vue'
import QuickAdd from '@/components/misc/QuickAdd.vue'
import SearchBar from '@core/layouts/components/app-navbar/components/SearchBar.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BBadge,
    NotificationDropdown,
    SearchBar,
    QuickAdd,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      avatarText,
    }
  },
  computed: {
    ...mapGetters({
      user: 'getCurrentUser',
    }),
  },
  methods: {
    logout() {
      this.$swal({
        title: 'Ingin logout?',
        text: 'Konfirmasi jika anda ingin logout dari aplikasi',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batal',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'text-danger btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.$store.dispatch('clearDeviceId')
          this.$store.dispatch('logout')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Berhasil logout!',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
.header-navbar .navbar-container ul.navbar-nav li.dropdown-user .dropdown-menu {
  margin-top: 20px;
}
</style>
