<template>
  <XyzTransition
    appear
    xyz="fade left-5 duration-3"
  >
    <div
      class="main-menu menu-fixed menu-accordion menu-shadow"
      :class="[
        { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
        skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
      ]"
      @mouseenter="updateMouseHovered(true)"
      @mouseleave="updateMouseHovered(false)"
    >
      <!-- main menu header-->
      <XyzTransition
        appear
        xyz="fade up-3 delay-2"
      >
        <div class="navbar-header expanded mb-50">
          <slot
            name="header"
            :toggleVerticalMenuActive="toggleVerticalMenuActive"
            :toggleCollapsed="toggleCollapsed"
            :collapseTogglerIcon="collapseTogglerIcon"
          >
            <ul class="nav navbar-nav flex-row">

              <!-- Logo & Text -->
              <li class="nav-item mr-auto">
                <b-link
                  class="navbar-brand"
                  to="/"
                >
                  <span class="brand-logo">
                    <b-img
                      :src="require('@/assets/images/logo/logo-icon.png')"
                    />
                  </span>
                  <span class="brand-text">
                    <b-img
                      height="38"
                      :src="require('@/assets/images/logo/logo-text.png')"
                    />
                  </span>
                </b-link>
              </li>

              <!-- Toggler Button -->
              <li class="nav-item nav-toggle mt-25">
                <b-link class="nav-link modern-nav-toggle">
                  <feather-icon
                    icon="XIcon"
                    size="20"
                    class="d-block d-xl-none"
                    @click="toggleVerticalMenuActive"
                  />
                  <feather-icon
                    icon="MenuIcon"
                    size="22"
                    class="d-none d-xl-block collapse-toggle-icon"
                    @click="toggleCollapsed"
                  />
                </b-link>
              </li>
            </ul>
          </slot>
        </div>
      </XyzTransition>
      <!-- / main menu header-->

      <!-- Shadow -->
      <div
        :class="{'d-block': shallShadowBottom}"
        class="shadow-bottom"
      />

      <!-- main menu content-->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="main-menu-content scroll-area"
        tagname="ul"
        @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
      >
        <XyzTransition
          appear
          xyz="fade up-3"
        >
          <div
            v-if="!isVerticalMenuCollapsed || isMouseHovered"
            class="mx-1 mb-2 mt-25"
          >
            <v-select
              v-if="!loadingReferenceDB"
              :value="selectedDB"
              placeholder="Pilih DB"
              :options="referenceDBList"
              label="name"
              :clearable="false"
              @option:selected="updateDB"
            />
            <div
              v-else
              class="d-flex"
            >
              <b-skeleton
                width="100%"
                height="40px"
                class="mb-0"
              />
            </div>
          </div>
        </XyzTransition>
        <vertical-nav-menu-items
          :items="navMenuItems"
          class="navigation navigation-main"
        />
        <div
          v-if="uploadList.length || processList.length"
          class="h-25"
        />
        <XyzTransition
          xyz="fade down-3"
        >
          <div
            v-if="(uploadList.length || processList.length) && (!isVerticalMenuCollapsed || isMouseHovered)"
            class="upload-queue p-1"
          >
            <XyzTransition
              appear
              xyz="fade left-3 delay-2"
            >
              <div
                v-if="uploadList.length"
              >
                <h6
                  class="text-muted font-weight-normal mb-1"
                >
                  Antrian Upload
                </h6>
              </div>
            </XyzTransition>
            <XyzTransitionGroup
              appear
              class="mb-50"
              xyz="fade stagger-2 left-3"
              mode="out-in"
            >
              <div
                v-for="upload in uploadList"
                :key="upload.name"
              >
                <div
                  class="d-flex justify-content-between mt-50"
                >
                  <small class="text-muted">
                    {{ upload.name }}
                  </small>
                  <small
                    v-if="!upload.error"
                    class="text-primary"
                  >
                    {{ upload.progress }}%
                  </small>
                </div>
                <XyzTransition
                  appear
                  xyz="fade front-3 flip-down-50%"
                  mode="out-in"
                >
                  <b-progress
                    v-if="upload.progress < 100 && !upload.error"
                    height="6px"
                    animated
                    max="100"
                    :value="upload.progress"
                    class="mt-50 mb-1"
                  />
                  <small
                    v-else-if="upload.error"
                    class="text-danger"
                  >
                    Gagal Diupload
                    <feather-icon
                      icon="XIcon"
                    />
                  </small>
                  <small
                    v-else
                    class="text-primary"
                  >
                    Berhasil Diupload
                    <feather-icon
                      icon="CheckIcon"
                    />
                  </small>
                </XyzTransition>
              </div>
            </XyzTransitionGroup>
            <XyzTransition
              appear
              xyz="fade left-3 delay-2"
            >
              <div
                v-if="processList.length"
              >
                <h6
                  class="text-muted font-weight-normal mb-1"
                >
                  Antrian Proses
                </h6>
              </div>
            </XyzTransition>
            <XyzTransitionGroup
              appear
              class="mb-50"
              xyz="fade stagger-2 left-3"
              mode="out-in"
            >
              <div
                v-for="process in processList"
                :key="process.id"
              >
                <div
                  class="d-flex align-items-center justify-content-between mt-50"
                >
                  <div class="w-100">
                    <small
                      :class="process.status === 'error' ? 'text-danger' : 'text-muted'"
                    >
                      {{ process.name }}
                    </small>
                    <XyzTransition
                      appear
                      xyz="fade front-3 flip-down-50%"
                      mode="out-in"
                    >
                      <div
                        v-if="process.status === 'loading'"
                        class="progress-loading mt-50 mb-50u"
                      />
                      <div v-else-if="process.status === 'error'">
                        <small class="text-muted">
                          Gagal memproses. <span class="text-primary cursor-pointer">Coba lagi?</span>
                        </small>
                      </div>
                      <div v-else-if="process.status === 'success'">
                        <small class="text-muted">
                          Proses berhasil ✨
                        </small>
                      </div>
                    </XyzTransition>
                  </div>
                  <div
                    v-if="process.status === 'success'"
                    class="pl-50"
                  >
                    <feather-icon
                      class="text-primary"
                      size="20"
                      icon="CheckIcon"
                    />
                  </div>
                </div>
              </div>
            </XyzTransitionGroup>
          </div>
        </XyzTransition>
      </vue-perfect-scrollbar>
      <!-- /main menu content-->
    </div>
  </XyzTransition>
</template>

<script>
import navMenuItems from '@/navigation/vertical'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
  BLink, BImg, BProgress, BSkeleton,
} from 'bootstrap-vue'
import { provide, computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import vSelect from 'vue-select'
import { createHelpers } from 'vuex-map-fields'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'

const { mapFields } = createHelpers({
  getterType: 'getDBField',
  mutationType: 'updateDBField',
})

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
    BProgress,
    BSkeleton,
    vSelect,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  computed: {
    ...mapFields([
      'selectedDB',
      'referenceDBList',
      'uploadList',
      'processList',
      'loadingReferenceDB',
    ]),
  },
  methods: {
    updateDB(data) {
      this.$swal({
        title: 'Ubah database?',
        text: 'Halaman akan dimuat ulang jika anda mengubah database.',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: ' text-danger btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('changeReferenceDB', data).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Berhasil mengubah reference DB!',
                icon: 'EditIcon',
                variant: 'success',
              },
            }, {
              position: 'top-left',
            })
            this.$router.go()
          })
        }
      })
    },
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
@import '@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/base/core/colors/palette-variables.scss';

.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu:not(.expanded) .navigation li.active a {
  background: linear-gradient(118deg, #6dab3c, rgba(109, 171, 60, 0.7));

  .dark-layout & {
    background: linear-gradient(118deg, #6dab3c, rgba(109, 171, 60, 0.7)) !important;
  }
}

.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu:not(.expanded) .navigation li.active a {
  color: #fff;
}

.upload-queue {
  position: fixed;
  width: 100%;
  background: #fff;
  left: 0;
  bottom: 0;
  box-shadow: 0 0 24px 0 rgba(34, 41, 47, 0.1);
}

.progress-loading {
  height: 5px;
  background: #e0f7fa;
  position: relative;
  overflow: hidden;
  border-radius: 2px;
}

.progress-loading::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  background: $primary;
  left: 0;
  right: 100%;
  animation: anim 1.5s cubic-bezier(0,0,.3,1) 1s infinite;
}

@keyframes anim {
  0% {
    left: -25%;
    right: 100%;
  }
  50% {
    left: 20%;
    right: 20%;
  }
  100% {
    left: 100%;
    right: -10%;
  }
}
</style>
